.TableComponent {
    padding-bottom: 24px;

    table,
    td,
    th {
        border: 1px solid black;
        border: 1px solid #e3e3e3;
    }

    table {
        margin: 24px;
        margin-bottom: 0;
        border-collapse: collapse;
        width: calc(100% - 48px);
    }

    th {
        font-size: 14px;
        height: 70px;
        padding: 5px;
    }

    td {
        text-align: right;
        padding: 5px;
    }
}

.Loading {
    padding-top: 20px;
    width: 100%;
    .card-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .card {
        background-color: #f5f5f5;
        border-radius: 5px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: auto;
        /* Adjust width as needed */
    }

    .skeleton {
        animation: shimmer 1.5s ease-in-out infinite;
    }

    @keyframes shimmer {
        0% {
            background-color: #f5f5f5;
        }

        50% {
            background-color: #e5e5e5;
        }

        100% {
            background-color: #f5f5f5;
        }
    }

    .card-image,
    .card-title,
    .card-text {
        height: 15px;
        /* Adjust heights as needed */
        background-color: #e5e5e5;
        margin-bottom: 5px;
        border-radius: 3px;
    }
}